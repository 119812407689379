// Disable transitions
* {
    -webkit-transition: 0ms !important;
    -moz-transition: 0ms !important;
    -o-transition: 0ms !important;
    transition: 0ms !important;
}

#root {
    width: 100%;
    height: 100%;
}

iframe {
    border: none; // For embedded GraphQL explorer
}

.clickable {
    cursor: pointer;
}

.inner-content {
    .ant-tabs-tab {
        padding-top: 0;
    }
    .ant-form-item-extra {
        font-size: 11px;
    }
}

$success-color: #52c41a;
$success-color-light: #95de64;

$blue-1: #e6f7ff;
$blue-0: lighten($blue-1, 3%);
$blue-2: #bae7ff;
$blue-3: #91d5ff;

$neutral-1: #ffffff;
$neutral-2: #fafafa;
$neutral-3: #f5f5f5;
$neutral-4: #f0f0f0;
$neutral-5: #d9d9d9;
$neutral-6: #bfbfbf;

.ant-btn.hover-active:not(:hover) {
    color: #bfbfbf;
}

.ant-btn-success {
    color: $success-color;
    border-color: $success-color;
    background: $neutral-1;

    &:hover,
    &:focus {
        background: $neutral-1;
        color: $success-color-light;
        border-color: $success-color-light;
    }
    &.ant-btn-primary {
        background: $success-color;
        color: $neutral-1;
    }
    &.ant-btn-primary:hover,
    &.ant-btn-primary:focus {
        background: $success-color-light;
        color: $neutral-1;
    }

    &[disabled],
    &[disabled]:hover,
    &[disabled]:focus,
    &[disabled]:active {
        background: $neutral-3;
        color: rgba(0, 0, 0, 0.25);
        border-color: $neutral-5;
        text-shadow: none;
        box-shadow: none;
    }
}

.ant-layout-sider:not(.ant-layout-sider-collapsed) .ant-layout-sider-trigger {
    background: transparent;
    width: 3em !important;
}

.ant-layout-sider .ant-layout-sider-trigger:hover {
    color: #1890ff;
}

.entity-icon:not(:only-child) {
    margin-right: 6px;
}
.ant-menu .entity-icon {
    margin-right: 10px;
}
.ant-breadcrumb-link .entity-icon {
    margin-right: 0;
}

.ant-menu-item .ant-menu-item-icon,
.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
    min-width: unset;
    &+span {
        margin-left: 0;
    }
}

.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
    border: none;
}

.form-button-content .anticon {
    line-height: 1;
    vertical-align: middle;
}

.ant-select-tree-treenode .relation-label {
    display: none;
}

.ant-table-wrapper.entities {
    overflow-x: scroll;

    .ant-table-small .ant-table-thead > tr > th {
        // Annoying crazy hack to get json-collapse buttons positioned relative to the ant-table-cell, which is its grandparent
        // From https://stackoverflow.com/questions/25768069/position-element-inside-absolute-parent-with-respect-to-grandparent
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);

        &.highlight {
            background: $neutral-3;
        }
        &.relation {
            padding: 2px 8px;
            background: $neutral-3;
            text-align: center;
            &.highlight {
                background: $neutral-4;
            }
        }
    }
    .ant-table-tbody {
        > tr.ant-table-row {
            > td {
                background: $neutral-1;
                mark {
                    background: $neutral-4;
                }
                &.ant-table-cell {
                    word-break: break-word;
                }
                &.ant-table-column-sort {
                    background: $neutral-3;
                    mark {
                        background: $neutral-5;
                    }
                }
                &.odd-level {
                    background: $neutral-2;
                }
            }
            &:hover {
                > td {
                    background: $neutral-2;
                    mark {
                        background: $neutral-4;
                    }
                    &.ant-table-column-sort {
                        background: $neutral-4;
                        mark {
                            background: $neutral-5;
                        }
                    }
                    &.odd-level {
                        background: $neutral-3;
                        mark {
                            background: $neutral-5;
                        }
                    }
                    &.highlight {
                        background: $blue-1;
                        mark {
                            background: $blue-2;
                        }
                    }
                }
            }
        }
    }
}

.ant-tag {
    &.filter {
        margin-right: 0;
        &.label {
            background: $neutral-3;
            border-color: $neutral-5;
        }
        &.value {
            background: $blue-1;
            border-color: $blue-3;
        }
    }
}

.ant-modal-content {
    width: fit-content;
}